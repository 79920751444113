import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { baseApi } from 'libs/axios';
import { getRecaptchaToken } from 'libs/recaptcha';

import { parseData } from 'utils/parseData';

import { PaymentErrorResponse, PaymentParams, PaymentResponse } from './types';

export const usePay = (id: string) => {
  return useMutation<
    PaymentResponse,
    AxiosError<PaymentErrorResponse>,
    PaymentParams
  >({
    mutationFn: async (data) => {
      const { token: recaptchaToken } = await getRecaptchaToken('PAY');

      return await baseApi
        .post<PaymentResponse>(`/links/${id}/pay/`, {
          ...data,
          recaptcha_token: recaptchaToken,
        } satisfies PaymentParams)
        .then(parseData);
    },
  });
};
