import { useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { REACT_APP_RECAPTCHA_SITE_KEY } from 'config/environment';

import { useLinkId } from 'hooks/useLinkId';

import { Payment } from 'modules/checkout/screens/Payment';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Pagamento'), [t]);
  const id = useLinkId();

  const recaptchaScript = useMemo(() => {
    if (!REACT_APP_RECAPTCHA_SITE_KEY) {
      return undefined;
    }

    return [
      {
        src: `https://www.google.com/recaptcha/enterprise.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`,
      },
    ];
  }, []);

  useEffect(() => {
    PaymentFlowAnalytics.registerPaymentProperties(id);
  }, [id]);

  return (
    <>
      <Helmet script={recaptchaScript}>
        <title>{title}</title>
      </Helmet>
      <Payment id={id} title={title} />
    </>
  );
};

export default Page;
