import { keyframes, styled, Typography } from '@maistodos/design-system-web';

export const Container = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
});

const slideUp = keyframes({
  '0%': { transform: 'translateY(100%)', opacity: 0 },
  '100%': { transform: 'translateY(0)', opacity: 1 },
});

export const Balloon = styled('div', {
  backgroundColor: '#ffffff',
  borderRadius: '$medium',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  padding: '$spacing24',
  textAlign: 'center',
  maxWidth: '90%',
  animation: `${slideUp} 0.3s ease-out`,
  marginBottom: '$spacing16',
  pointerEvents: 'auto',
});

export const Title = styled(Typography, {
  marginBottom: '$spacing8',
});

Title.defaultProps = {
  variant: 'large',
  weight: 'bold',
  css: {
    color: '$neutral600',
  },
};

export const CountdownText = styled(Typography, {
  fontVariantNumeric: 'tabular-nums',
  marginBottom: '$spacing16',
  textWrap: 'pretty',
});

CountdownText.defaultProps = {
  css: {
    color: '$neutral600',
  },
};
