import { REACT_APP_RECAPTCHA_SITE_KEY } from 'config/environment';

import { Response } from 'libs/recaptcha/types';

export const getRecaptchaToken = async (action: string) => {
  return new Promise<Response>((resolve) => {
    if (!REACT_APP_RECAPTCHA_SITE_KEY) {
      resolve({ error: 'Recaptcha environment not configured' });
      return;
    }

    if (!window.grecaptcha?.enterprise) {
      resolve({ error: 'Recaptcha not loaded' });
      return;
    }

    window.grecaptcha.enterprise.ready(async () => {
      try {
        const token = await window.grecaptcha?.enterprise?.execute(
          String(REACT_APP_RECAPTCHA_SITE_KEY),
          { action }
        );

        resolve({
          token,
        });
      } catch (error) {
        const err = error as Error;
        resolve({ error: err.message });
      }
    });
  });
};
