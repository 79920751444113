export const BASE_API = process.env.REACT_APP_BASE_API;

export const IS_DEV = process.env.NODE_ENV === 'development';

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const MIXPANEL_APP_ORIGIN = process.env.REACT_APP_MIXPANEL_APP_ORIGIN;

export const REACT_APP_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY;
