import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Balloon,
  Container,
  CountdownText,
  Title,
} from 'modules/checkout/components/CountdownRedirect/styles';
import { CountdownRedirectProps } from 'modules/checkout/components/CountdownRedirect/types';

export default function CountdownRedirect({
  redirectUrl,
}: CountdownRedirectProps) {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }

    return () => clearTimeout(timer);
  }, [countdown, redirectUrl]);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = redirectUrl;
    }
  }, [countdown, redirectUrl]);

  return (
    <Container>
      <Balloon>
        <Title>
          {t('Redirecionando em {{countdown}} segundo(s).', { countdown })}
        </Title>
        <CountdownText>
          {t('Você será redirecionado para a página de origem.')}
        </CountdownText>
      </Balloon>
    </Container>
  );
}
