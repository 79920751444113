import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { PaymentOption } from 'entities/PaymentType';

import { baseApi } from 'libs/axios';
import { getRecaptchaToken } from 'libs/recaptcha';

import {
  PaymentErrorResponse,
  PaymentParams,
  PaymentResponse,
} from 'services/usePay/types';

import { parseData } from 'utils/parseData';

export const usePayGenerate = (
  type: PaymentOption.Pix | PaymentOption.BankSlip,
  id: string,
  paymentType: number
) => {
  return useQuery<
    PaymentResponse,
    AxiosError<PaymentErrorResponse>,
    PaymentResponse
  >({
    queryKey: ['usePayGenerate', type, id, paymentType],
    queryFn: async () => {
      const { token: recaptchaToken } = await getRecaptchaToken(
        'GENERATE_PAY_CODE'
      );

      const response = await baseApi
        .post<PaymentResponse>(`/links/${id}/pay/`, {
          payment_type: paymentType,
          recaptcha_token: recaptchaToken,
        } satisfies PaymentParams)
        .then(parseData);

      if (
        type === PaymentOption.BankSlip &&
        (!response.bankslip?.barcode || !response.bankslip?.exp_date)
      ) {
        throw new Error('Não foi possível gerar o boleto');
      }

      return response;
    },
  });
};
